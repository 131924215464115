var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
export var SurplusCalculationStatus;
(function (SurplusCalculationStatus) {
    SurplusCalculationStatus["Unprocessed"] = "unprocessed";
    SurplusCalculationStatus["Queued"] = "queued";
    SurplusCalculationStatus["Processing"] = "processing";
    SurplusCalculationStatus["Completed"] = "completed";
    SurplusCalculationStatus["Failed"] = "failed";
    SurplusCalculationStatus["Finalized"] = "finalized";
})(SurplusCalculationStatus || (SurplusCalculationStatus = {}));
const recordTypeId = 'SurplusCalculation';
let SurplusCalculation = class SurplusCalculation {
    id;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    name;
    description;
    status;
    statusMessage;
    policyPeriod;
    filter;
    totalDistributed;
    approvedAmount;
    claimValuationDate;
    approvedDate;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], SurplusCalculation.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], SurplusCalculation.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], SurplusCalculation.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], SurplusCalculation.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], SurplusCalculation.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], SurplusCalculation.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], SurplusCalculation.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], SurplusCalculation.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], SurplusCalculation.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], SurplusCalculation.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], SurplusCalculation.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], SurplusCalculation.prototype, "description", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Status',
        codeList: [
            { code: SurplusCalculationStatus.Unprocessed, description: 'Unprocessed' },
            { code: SurplusCalculationStatus.Queued, description: 'Queued' },
            { code: SurplusCalculationStatus.Processing, description: 'Processing' },
            { code: SurplusCalculationStatus.Completed, description: 'Completed' },
            { code: SurplusCalculationStatus.Failed, description: 'Failed' },
            { code: SurplusCalculationStatus.Finalized, description: 'Finalized' },
        ],
    })
], SurplusCalculation.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId })
], SurplusCalculation.prototype, "statusMessage", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy Period', fieldType: 'year' })
], SurplusCalculation.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb' })
], SurplusCalculation.prototype, "filter", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Total Distributed', fieldType: 'currency' })
], SurplusCalculation.prototype, "totalDistributed", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Approved Amount', fieldType: 'currency' })
], SurplusCalculation.prototype, "approvedAmount", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Claim Valuation Date', fieldType: 'date' })
], SurplusCalculation.prototype, "claimValuationDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Approved Date', fieldType: 'date' })
], SurplusCalculation.prototype, "approvedDate", void 0);
SurplusCalculation = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        supports: {
            views: true,
            notes: true,
            tasks: true,
            docTemplates: true
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        hasBackingTable: true,
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        displayNameSingular: 'Surplus Calculation',
        displayNamePlural: 'Surplus Calculations',
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: [
                'SURPLUS-CALCULATION-COMPLETED',
                'SURPLUS-CALCULATION-FINALIZED',
                'SURPLUS-CALCULATION-FAILED',
            ],
        },
        roleCategory: RoleCategories.SurplusCalculations,
    })
], SurplusCalculation);
export { SurplusCalculation };
