var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PolicyBillingSchedule';
let PolicyBillingSchedule = class PolicyBillingSchedule {
    id;
    policyId;
    policy;
    config;
    createUserId;
    createUser;
    createdUTC;
    modifiedUserId;
    modifiedUser;
    modifiedUTC;
    deleteUserId;
    deleteUser;
    deletedUTC;
    status;
    billingScheduleType;
    billingScheduleTypeId;
    invoice;
    invoiceId;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyBillingSchedule.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], PolicyBillingSchedule.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], PolicyBillingSchedule.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], PolicyBillingSchedule.prototype, "config", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], PolicyBillingSchedule.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyBillingSchedule.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyBillingSchedule.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], PolicyBillingSchedule.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyBillingSchedule.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyBillingSchedule.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], PolicyBillingSchedule.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyBillingSchedule.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyBillingSchedule.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Status',
        jsonStorage: false,
    })
], PolicyBillingSchedule.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyBillingScheduleType' })
], PolicyBillingSchedule.prototype, "billingScheduleType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        referenceType: 'PolicyBillingScheduleType',
        fieldType: 'uuid',
    })
], PolicyBillingSchedule.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], PolicyBillingSchedule.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, referenceType: 'Invoice', fieldType: 'uuid' })
], PolicyBillingSchedule.prototype, "invoiceId", void 0);
PolicyBillingSchedule = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        hasBackingTable: true,
        displayNameSingular: 'Policy Billing Schedule',
        displayNamePlural: 'Policy Billing Schedules',
    })
], PolicyBillingSchedule);
export { PolicyBillingSchedule };
