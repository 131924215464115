import { Injectable, Optional, SkipSelf, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Hub } from 'aws-amplify/utils';
import { SessionUrlService } from './session-url.service';
import { environment } from 'src/environments/environment';
import { AuthUser, confirmResetPassword, fetchAuthSession, getCurrentUser, resetPassword, signIn, signOut, updatePassword } from 'aws-amplify/auth';

export interface ICognitoUserInput {
  username: string;
  password: string;
  showPassword?: boolean;
  code?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private readonly _signOuts = new Subject<boolean>();
  readonly signOuts$ = this._signOuts.asObservable();
  urlService = inject(SessionUrlService);

  private readonly _signIns = new Subject<{
    user?: AuthUser;
    token?: any;
    fromCode?: boolean;
  } | null>();
  readonly signIns$ = this._signIns.asObservable();

  private readonly _refreshToken = new Subject<any>();
  readonly refreshToken$ = this._refreshToken.asObservable();

  private readonly _oauthStates = new Subject<any>();
  readonly oauthStates$ = this._oauthStates.asObservable();

  constructor(@Optional() @SkipSelf() parent?: CognitoService) {
    if (parent) {
      throw Error('attempted to create duplicate CognitoService');
    }

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload }) => {
      // console.log('Hub Event', JSON.stringify(payload.event), payload.message);
      switch (payload.event) {
        case 'signedIn':
          this._signIns.next({ user: payload.data });
          break;
        case 'signedOut':
          this._signOuts.next(true);
          break;
        case 'customOAuthState':
          this._oauthStates.next({ state: payload.data });
          break;
        case 'tokenRefresh':
          void this.getToken().then((next) => {
            this._refreshToken.next({ newToken: next });
          });
          break;
        default:
          console.warn('Unhandled AuthEvent', event);
      }
    });
  }

  async currentAuthenticatedUser(options: { forceRefresh?: boolean } = { forceRefresh: false }) {
    let user: AuthUser | null = null;
    try {
      user = await getCurrentUser();
    } catch (e) {
      console.warn('unable to get current user', e);
    }

    let token = null;
    try {
      token = await this.getToken({ forceRefresh: user === null });
    } catch (e) {
      console.warn('unable to get current token', e);
    }

    if (!!token && !user) {
      user = await getCurrentUser();
    }

    return Promise.resolve({ user, token });
  }

  public async getToken(options: { secondRun?: boolean, forceRefresh?: boolean } = { secondRun: false, forceRefresh: false }): Promise<string> {
    const session = await fetchAuthSession({ forceRefresh: options.forceRefresh });
    if (session) {
      return Promise.resolve(session.tokens?.accessToken?.toString() ?? '');
    }
    if (!options.secondRun) {
      await this.refreshToken();
      return this.getToken({ secondRun: true });
    }
    console.error('could not get token after attempting refresh');
    return Promise.reject('could not get token even after refresh');
  }

  signIn(user: ICognitoUserInput) {
    return signIn({
      username: user.username.toLowerCase(), password: user.password
    });
  }

  public signOut() {
    return signOut();
  }

  async forgotPassword(username: string) {
    const tenant = this.urlService.getTenantFromCurrentUrl();
    if (!tenant) {
      throw Error('Unable to determine tenant');
    }

    return await resetPassword({
      username: username.toLowerCase(),
      options: {
        clientMetadata: {
          env: environment.env,
          tenant: tenant,
        }
      }
    });
  }

  async resetPassword(username: string, newPassword: string, code: string) {
    return await confirmResetPassword({
      username: username.toLowerCase(),
      newPassword: newPassword,
      confirmationCode: code
    });
  }

  async changePassword(oldPassword: string, newPassword: string) {
    return await updatePassword({ oldPassword: oldPassword, newPassword: newPassword });
  }

  public async refreshToken() {
    try {
      await fetchAuthSession({ forceRefresh: true });
      window.location.reload();
    } catch (e) {
      console.error('unable to refresh token', e);
    }
  }
}
