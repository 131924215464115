export var CommissionBasisType;
(function (CommissionBasisType) {
    CommissionBasisType["collected"] = "collected";
    CommissionBasisType["written"] = "written";
})(CommissionBasisType || (CommissionBasisType = {}));
export var PaymentScheduleFrequencyType;
(function (PaymentScheduleFrequencyType) {
    PaymentScheduleFrequencyType["monthly"] = "monthly";
    PaymentScheduleFrequencyType["quarterly"] = "quarterly";
})(PaymentScheduleFrequencyType || (PaymentScheduleFrequencyType = {}));
