export const dayOfWeekIndex = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 0,
};
export const RelativeDateTokens = {
    now: 'Current date',
    eom: 'End of the month',
    eofm: 'End of the following month',
    eopm: 'End of the prior month',
    bom: 'Beginning of the month',
    bofm: 'Beginning of the following month',
    bopm: 'Beginning of the prior month',
    eoy: 'End of the year',
    eofy: 'End of the following year',
    eopy: 'End of the prior year',
    boy: 'Beginning of then year',
    bofy: 'Beginning of the following year',
    bopy: 'Beginning of the prior year',
    eow: 'End of the week',
    eofw: 'End of the following week',
    eopw: 'End of the prior week',
    bow: 'Beginning of then week',
    bofw: 'Beginning of the following week',
    bopw: 'Beginning of the prior week',
    eoq: 'End of the quarter',
    eofq: 'End of the following quarter',
    eopq: 'End of the prior quarter',
    boq: 'Beginning of then quarter',
    bofq: 'Beginning of the following quarter',
    bopq: 'Beginning of the prior quarter'
};
export const RELATIVE_DATE_REGEXP = /^(?<sign>[\+-]*)(?<val>\d*)(?<unit>(day(s*)|month(s*)|week(s*)|year(s*)|quarter(s*)|[mdywq]))$/g;
export function isValidRelativeDateString(input) {
    if (!!!input) {
        return false;
    }
    if (Object.keys(RelativeDateTokens).includes(input)) {
        return true;
    }
    if (input.match(RELATIVE_DATE_REGEXP)) {
        return true;
    }
    return false;
}
