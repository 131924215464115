var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
import { InvoiceStatusType } from '../../invoices/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'Invoice';
let Invoice = class Invoice {
    //pk
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    //fk
    policyholderId;
    policyHolder;
    //fk
    policyId;
    policy;
    //not persisted
    payments;
    //fk
    masterInvoiceId;
    installmentId;
    billingScheduleTypeId;
    issueDate;
    dueDate;
    //jdata
    invoiceNumber; //todo: required?
    status;
    description;
    subTotal;
    taxes;
    total;
    lineItems;
    payrollReportId;
    policyAuditId;
    isLateFeeInvoice;
    isMasterInvoice;
    isInstallmentInvoice;
    includesDeposit;
    partiallyPaid;
    sourceInvoiceId;
    //custom properties
    datePaid;
    invoiceForm;
    taxRate;
    invoiceType;
    sourceId;
    assessmentId;
    //Unexposed Properties
    //invoiceInstallmentId?: string;
    balance;
    payMethod;
    payNotes;
    comments;
    gracePeriodEndDate;
    cancelNoticeDate;
    invoiceRuleSetId;
    invoiceRuleSet;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Invoice.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Invoice.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Invoice.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Invoice.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Invoice.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Invoice.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Invoice.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Invoice.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Invoice.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Invoice.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        referenceType: 'PolicyHolder',
        label: 'Policyholder Id',
    })
], Invoice.prototype, "policyholderId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        label: 'Policyholder',
        overrideJoinProperty: 'policyholderId',
    })
], Invoice.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], Invoice.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], Invoice.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'InvoiceAppliedPayment', isList: true })
], Invoice.prototype, "payments", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Master Invoice',
        referenceType: 'Invoice',
    })
], Invoice.prototype, "masterInvoiceId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Invoice Installment',
        referenceType: 'InvoiceInstallment',
    })
], Invoice.prototype, "installmentId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Billing Schedule Type ',
        referenceType: 'PolicyBillingScheduleType',
        disableField: true,
    })
], Invoice.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Issue Date',
        fieldType: 'date',
        jsonStorage: true,
    })
], Invoice.prototype, "issueDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Due Date',
        fieldType: 'date',
        jsonStorage: true,
    })
], Invoice.prototype, "dueDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Invoice.prototype, "invoiceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, codeEnum: InvoiceStatusType })
], Invoice.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Invoice.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Invoice.prototype, "subTotal", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Invoice.prototype, "taxes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Invoice.prototype, "total", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'InvoiceLine',
        isList: true,
    })
], Invoice.prototype, "lineItems", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Invoice.prototype, "payrollReportId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Invoice.prototype, "policyAuditId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Invoice.prototype, "isLateFeeInvoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Invoice.prototype, "isMasterInvoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Invoice.prototype, "isInstallmentInvoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Invoice.prototype, "includesDeposit", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Invoice.prototype, "partiallyPaid", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Invoice.prototype, "sourceInvoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true, disableField: true })
], Invoice.prototype, "datePaid", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Invoice.prototype, "invoiceForm", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true, disableField: true })
], Invoice.prototype, "taxRate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Invoice.prototype, "invoiceType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Invoice.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: true, disableField: true })
], Invoice.prototype, "assessmentId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Invoice.prototype, "balance", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Invoice.prototype, "payMethod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Invoice.prototype, "payNotes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Invoice.prototype, "comments", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Invoice.prototype, "gracePeriodEndDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Invoice.prototype, "cancelNoticeDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'InvoiceRuleSet' })
], Invoice.prototype, "invoiceRuleSetId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'InvoiceRuleSet' })
], Invoice.prototype, "invoiceRuleSet", void 0);
Invoice = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Invoice',
        displayNamePlural: 'Invoices',
        supports: {
            views: true,
            files: true,
            notes: true,
            tasks: true,
            docTemplates: true,
            globalSearch: true,
        },
        globalSearchPaths: ['invoiceNumber'],
        dataModelCustomization: {
            allowCustomFields: true,
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'policyHolder',
                filterType: FilterType.Equals,
                width: 'x-large',
            },
            {
                recordType: recordTypeId,
                fieldPath: 'invoiceNumber',
                filterType: FilterType.StartsWith,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'policy.policyNumber',
                filterType: FilterType.StartsWith,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'status',
                filterType: FilterType.Equals,
            },
        ],
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: ['INVOICE-WRITTEN-OFF', 'INVOICE-LINE-ITEMS-WRITTEN-OFF', 'INVOICE-VOIDED', 'INVOICE-BALANCE-WRITTEN-OFF'],
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        lookupProperties: { resultProperties: ['invoiceNumber'] },
        roleCategory: RoleCategories.Invoices,
        recordHistoryConfig: {
            enable: true,
        },
        usesNewDataModel: true,
    })
], Invoice);
export { Invoice };
