var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
export const RecordHistoryChangeStates = {
    created: 'CREATED',
    updated: 'UPDATED',
    deleted: 'DELETED',
};
const recordTypeId = 'RecordHistory';
let RecordHistory = class RecordHistory {
    id;
    initiatedByUserId;
    initiatedByUser;
    ts;
    recordType;
    recordId;
    relatedRecordType;
    relatedRecordId;
    changeType;
    changeDetails;
    typeIcon;
    awsContext;
    eventLogId;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], RecordHistory.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Initiated By', fieldType: 'uuid', referenceType: 'User' })
], RecordHistory.prototype, "initiatedByUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Initiated By', fieldType: 'User' })
], RecordHistory.prototype, "initiatedByUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Timestamp', fieldType: 'datetime' })
], RecordHistory.prototype, "ts", void 0);
__decorate([
    FieldData({ recordTypeId })
], RecordHistory.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], RecordHistory.prototype, "recordId", void 0);
__decorate([
    FieldData({ recordTypeId })
], RecordHistory.prototype, "relatedRecordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], RecordHistory.prototype, "relatedRecordId", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: RecordHistoryChangeStates })
], RecordHistory.prototype, "changeType", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], RecordHistory.prototype, "changeDetails", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], RecordHistory.prototype, "typeIcon", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], RecordHistory.prototype, "awsContext", void 0);
RecordHistory = __decorate([
    RecordTypeData({
        usesNewDataModel: true,
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Record History',
        displayNamePlural: 'Record History',
        dataModelCustomization: {
            hideFromAdmin: true
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'changeType',
                filterType: FilterType.Equals,
                width: 'small',
            },
            {
                recordType: recordTypeId,
                fieldPath: 'ts',
                filterType: FilterType.Between,
                width: 'small',
            },
            {
                recordType: recordTypeId,
                fieldPath: 'initiatedByUser',
                label: 'By User',
                filterType: FilterType.Equals,
                width: 'large',
            },
        ],
    })
], RecordHistory);
export { RecordHistory };
