import { addDays, addMonths, addQuarters, addWeeks, addYears, endOfMonth, endOfQuarter, endOfWeek, endOfYear, getDay, parseISO, setDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear, format } from 'date-fns';
import { RelativeDateTokens, dayOfWeekIndex, RELATIVE_DATE_REGEXP } from './models';
export function getFormattedRelativeDateString(input, formatString, asOf) {
    const dt = getRelativeDate(input, asOf);
    if (formatString.toLowerCase() === 'iso') {
        return dt.toISOString();
    }
    if (formatString.toLowerCase() === 'iso-date') {
        return dt.toISOString().substring(0, 10);
    }
    return format(getRelativeDate(input, asOf), formatString);
}
export function getRelativeDateString(input, asOf) {
    console.log('getRelativeDateString', input, asOf);
    return format(getRelativeDate(input, asOf), 'yyyy-MM-dd');
}
export function getNthDayOfWeek(weekday, n, asOf) {
    const startDate = getStartDate(asOf);
    const firstWeekDay = setDay(startOfMonth(startDate), dayOfWeekIndex[weekday], {
        weekStartsOn: getDay(startOfMonth(startDate)),
    });
    return addWeeks(firstWeekDay, n - 1);
}
export function getNthDayOfWeekString(weekday, n, asOf) {
    return getNthDayOfWeek(weekday, n, asOf).toISOString().substring(0, 10);
}
function getStartDate(asOf) {
    return !asOf ? new Date() : typeof asOf === 'string' ? parseISO(asOf) : asOf;
}
export function getRelativeDate(input, asOf) {
    const startDate = getStartDate(asOf);
    if (input.toLowerCase() === 'now') {
        return startDate;
    }
    if (Object.keys(RelativeDateTokens).includes(input)) {
        return getDateFuncValue(input, startDate);
    }
    for (const match of input.matchAll(RELATIVE_DATE_REGEXP)) {
        const { sign, val, unit } = match.groups;
        const valFactor = sign && sign === '-' ? -1 : 1;
        if (getUnitFunc(unit) !== undefined) {
            return getUnitFunc(unit)(startDate, valFactor * Number(val));
        }
    }
    throw Error(`Unable to getRelativeDate from input ${input}`);
}
function getDateFuncValue(input, asOf) {
    const unit = input.slice(-1);
    const startOf = input.substring(0, 1) === 'b';
    const following = input.length > 3 && input.substring(2, 3) === 'f';
    const prior = input.length > 3 && input.substring(2, 3) === 'p';
    switch (unit) {
        case 'w': {
            const useDate = (following || prior) ? addWeeks(asOf, following ? 1 : -1) : asOf;
            return startOf ? startOfWeek(useDate) : endOfWeek(useDate);
        }
        case 'm': {
            const useDate = (following || prior) ? addMonths(asOf, following ? 1 : -1) : asOf;
            return startOf ? startOfMonth(useDate) : endOfMonth(useDate);
        }
        case 'y': {
            const useDate = (following || prior) ? addYears(asOf, following ? 1 : -1) : asOf;
            return startOf ? startOfYear(useDate) : endOfYear(useDate);
        }
        case 'q': {
            const useDate = (following || prior) ? addQuarters(asOf, following ? 1 : -1) : asOf;
            return startOf ? startOfQuarter(useDate) : endOfQuarter(useDate);
        }
    }
    throw Error(`Unable to getDateFuncValue from input ${input}`);
}
function getUnitFunc(unitInput) {
    switch (unitInput.toLowerCase()) {
        case 'd':
        case 'day':
        case 'days':
            return addDays;
        case 'm':
        case 'month':
        case 'months':
            return addMonths;
        case 'w':
        case 'week':
        case 'weeks':
            return addWeeks;
        case 'y':
        case 'year':
        case 'years':
            return addYears;
        case 'q':
        case 'quarter':
        case 'quarters':
            return addQuarters;
    }
}
