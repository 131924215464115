import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { AuthService } from './core/auth/auth.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RouterService } from './core/services/router-data.service';
import { TitleService } from './services/title.service';
import { UpdateAppService } from './core/serviceWorkers/update-app.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { FileDownloadListenerService } from './core/services/file-download-listener.service';
import { RecentlyViewedService } from './core/services/recently-viewed.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    authService: AuthService,
    private fileDownloadService: FileDownloadListenerService,
    private gtmService: GoogleTagManagerService,
    private updateAppService: UpdateAppService,
    private routerService: RouterService,
    private titleService: TitleService,
    private recentlyViewedService: RecentlyViewedService
  ) {
    console.log('SourceSHA', environment.sourceSHA);
    router.canceledNavigationResolution = 'computed';
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      console.log('navigationEnd event being called');
      // Ensure default title is set only if no title has been explicitly set during navigation
      this.titleService.ensureTitleAfterNavigation();
      // Reset the title set flag for the next navigation
      this.titleService.resetTitleFlag();
      this.recentlyViewedService.evalRoute(this.route.snapshot);
    });

    //console.log('env vars', environment);
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.cognitoUserpoolId,
          userPoolClientId: environment.cognitoWebclientId,
          loginWith: {
            username: true,
            oauth: {
              domain: environment.cognitoDomain,
              scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: [`https://${environment.baseHostName}/oauth/sign-in`],
              redirectSignOut: [`https://${environment.baseHostName}/oauth/sign-out`],
              responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
          }
        }
      }
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage(
      {
        // - Cookie domain (only required if cookieStorage is provided)
        domain: environment.baseHostName,
        // (optional) - Cookie path
        path: '/',
        // (optional) - Cookie expiration in days
        expires: 365,
        // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'strict',
        // (optional) - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      }
    ));
  }

  //todo: evaluate this logic or remove.  added voids vs fixing awaits
  async ngOnInit() {
    // push GTM data layer for every visited page
    await this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        void this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
