var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'AutoNumberCounterValue';
let AutoNumberCounterValue = class AutoNumberCounterValue {
    id;
    autoNumberCounterId;
    pathGroup;
    lastValue;
    modifiedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutoNumberCounterValue.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'AutoNumberCounter' })
], AutoNumberCounterValue.prototype, "autoNumberCounterId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], AutoNumberCounterValue.prototype, "pathGroup", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], AutoNumberCounterValue.prototype, "lastValue", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], AutoNumberCounterValue.prototype, "modifiedUTC", void 0);
AutoNumberCounterValue = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        roleCategory: RoleCategories.AutoNumberFormulas,
        displayNameSingular: 'Auto Number Counter Value',
        displayNamePlural: 'Auto Number Counter Values'
    })
], AutoNumberCounterValue);
export { AutoNumberCounterValue };
