var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
const recordTypeId = 'Agency';
let Agency = class Agency {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    name;
    email;
    agencyNumber;
    addresses;
    billingAddress;
    mailingAddress;
    physicalAddress;
    agencies;
    phones;
    status;
    appointedDate;
    expirationDate;
    //do we need these?
    city;
    state;
    postalCode;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agency.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agency.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agency.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agency.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Agency.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Agency.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Agency.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Agency.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Agency.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Agency.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agency.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agency.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agency.prototype, "agencyNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true, isList: true })
], Agency.prototype, "addresses", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Billing Address', fieldType: 'Address', jsonStorage: true })
], Agency.prototype, "billingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Mailing Address', fieldType: 'Address', jsonStorage: true })
], Agency.prototype, "mailingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Physical Address', fieldType: 'Address', jsonStorage: true })
], Agency.prototype, "physicalAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agency', jsonStorage: true, isList: true })
], Agency.prototype, "agencies", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Phone', jsonStorage: true, isList: true })
], Agency.prototype, "phones", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agency.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Agency.prototype, "appointedDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Agency.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agency.prototype, "city", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agency.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Postal', jsonStorage: true })
], Agency.prototype, "postalCode", void 0);
Agency = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Agency',
        displayNamePlural: 'Agencies',
        roleCategory: RoleCategories.AgencyManagement,
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [
            TrailMapType.HomePage,
            TrailMapType.Detail,
            TrailMapType.QuickAdd,
            TrailMapType.DataEntry,
        ],
        lookupProperties: {
            resultProperties: ['name', 'agencyNumber', 'status'],
            filterProperties: ['name', 'agencyNumber'],
        },
        supports: {
            views: true,
            docTemplates: true,
            files: true,
            notes: true,
        },
        usesNewDataModel: true,
        workflow: { enabled: true, generalEvents: true },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'agencyNumber',
                filterType: FilterType.Contains,
            },
        ],
    })
], Agency);
export { Agency };
