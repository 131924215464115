var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'AutoNumberFormula';
let AutoNumberFormula = class AutoNumberFormula {
    id;
    name;
    recordType;
    fieldPath;
    isDisabled;
    sequence;
    mapping;
    filterGroup;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutoNumberFormula.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], AutoNumberFormula.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'recordTypeLookup' })
], AutoNumberFormula.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', label: 'Field' })
], AutoNumberFormula.prototype, "fieldPath", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], AutoNumberFormula.prototype, "isDisabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], AutoNumberFormula.prototype, "sequence", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', disableField: true, jsonStorage: true })
], AutoNumberFormula.prototype, "mapping", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', label: 'Filter', jsonStorage: true })
], AutoNumberFormula.prototype, "filterGroup", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], AutoNumberFormula.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], AutoNumberFormula.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], AutoNumberFormula.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], AutoNumberFormula.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], AutoNumberFormula.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], AutoNumberFormula.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], AutoNumberFormula.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], AutoNumberFormula.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], AutoNumberFormula.prototype, "deletedUTC", void 0);
AutoNumberFormula = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Auto Number Formula',
        displayNamePlural: 'Auto Number Formulas',
        roleCategory: RoleCategories.AutoNumberFormulas,
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'recordType',
                filterType: FilterType.Equals,
            },
        ],
    })
], AutoNumberFormula);
export { AutoNumberFormula };
