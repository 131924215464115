import { DataModelRecordTypes, basicFieldTypes, } from './record-types';
import { recordTypeMetadataCache, splitCamelCase } from './meta-data-decorators';
import { isUUID } from '../util/guid-helpers';
export function initCoreMetaData() {
    //console.log('initCoreMetaData called');
    if (recordTypeMetadataCache.size > 0) {
        return;
    }
    for (const recClass of Object.keys(DataModelRecordTypes)) {
        const item = new DataModelRecordTypes[recClass]();
    }
}
export function combineRecordTypes(tenantTypes, featureTypes) {
    const recTypes = [
        ...tenantTypes.map((m) => {
            return { ...m, scope: 'tenant' };
        }),
    ];
    for (const [coreRecTypeId, coreRecType] of recordTypeMetadataCache.entries()) {
        if (!featureTypes.find((f) => f.toUpperCase() === coreRecTypeId.toUpperCase())) {
            continue;
        }
        const nameMatchSingular = recTypes.findIndex((r) => r.displayNameSingular?.toLowerCase() === coreRecType.displayNameSingular.toLowerCase()) >= 0;
        const nameMatchPlural = recTypes.findIndex((r) => r.displayNamePlural?.toLowerCase() === coreRecType.displayNamePlural.toLowerCase()) >= 0;
        recTypes.push({
            id: coreRecTypeId,
            tenant: '#system#',
            scope: 'system',
            alias: coreRecType.alias ?? coreRecTypeId,
            displayNameSingular: !nameMatchSingular
                ? coreRecType.displayNameSingular
                : `${coreRecType.displayNameSingular} (system)`,
            displayNamePlural: !nameMatchPlural
                ? coreRecType.displayNamePlural
                : `${coreRecType.displayNamePlural} (system)`,
            hasBackingTable: coreRecType.hasBackingTable,
            idProperty: coreRecType.idProperty,
            idIsString: coreRecType.idIsString,
            dataModelCustomization: coreRecType.dataModelCustomization,
            trailMapTypes: coreRecType.trailMapTypes,
            supports: coreRecType.supports,
            roleCategory: coreRecType.roleCategory,
            security: coreRecType.security,
            lookupProperties: coreRecType.lookupProperties,
            header: coreRecType.header,
            fields: [],
            usesNewDataModel: coreRecType.usesNewDataModel,
            quickFilterFields: coreRecType.quickFilterFields,
            navMenuConfiguration: coreRecType.navMenuConfiguration,
            workflow: coreRecType.workflow,
            displayGenerationTemplate: coreRecType.displayGenerationTemplate,
        });
    }
    return recTypes;
}
export function dataModelTypeIsStringType(inputType) {
    return checkBasicType(inputType, ['string']);
}
export function dataModelTypeIsDateType(inputType) {
    return checkBasicType(inputType, ['date', 'datetime']);
}
export function dataModelTypeIsNumberType(inputType) {
    return checkBasicType(inputType, [
        'decimal',
        'integer',
        'currency',
        'percentage',
        'percentage-whole-number',
    ]);
}
function checkBasicType(inputType, matchTypes) {
    if (!inputType) {
        return false;
    }
    return !!matchTypes.find((f) => f === inputType);
}
export function isBasicFieldType(inputType) {
    if (!!!inputType) {
        return false;
    }
    return basicFieldTypes.find((f) => f.key.toLowerCase() === inputType.toLowerCase()) !== undefined;
}
function addDefaultTenantRecordTypeField(tenantFields, recordType, fieldType, refName, label) {
    if (recordType.scope !== 'tenant') {
        return;
    }
    if (tenantFields && tenantFields.find((f) => f.refName === refName)) {
        return;
    }
    tenantFields.push({
        scope: 'system',
        tenant: '#system#',
        recordTypeId: recordType.id,
        fieldType: fieldType,
        refName: refName,
        id: refName,
        label: label ?? splitCamelCase(refName),
        jsonStorage: false,
    });
}
export function setRecordTypeFields(tenantFields, recordType) {
    const recTypeKey = recordType.id.toUpperCase();
    //#region Tenant Record Type Default Fields
    if (recordType.scope === 'tenant' && (recordType.hasBackingTable ?? false)) {
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'uuid', 'id', 'ID');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'uuid', 'createUserId');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'uuid', 'modifiedUserId');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'uuid', 'deleteUserId');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'User', 'createUser', 'Created By');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'User', 'modifiedUser', 'Modified By');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'User', 'deleteUser', 'Deleted By');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'datetime', 'createdUTC', 'Created On');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'datetime', 'modifiedUTC', 'Modified On');
        addDefaultTenantRecordTypeField(tenantFields, recordType, 'datetime', 'deletedUTC', 'Deleted On');
    }
    //#endregion
    const fields = tenantFields.map((m) => {
        const tenantField = {
            ...m,
            scope: m.scope ?? 'tenant',
        };
        if (tenantField.jsonStorage === undefined && isUUID(m.id)) {
            tenantField.jsonStorage = true;
        }
        return tenantField;
    }) ?? [];
    //Core Fields
    if (recordTypeMetadataCache.has(recTypeKey)) {
        const coreRecType = recordTypeMetadataCache.get(recTypeKey);
        if (coreRecType.fields) {
            for (const coreField of coreRecType.fields) {
                const idMatch = fields
                    ? fields.find((r) => r.recordTypeId === coreField.recordTypeId && r.id?.toUpperCase() === coreField.refName?.toUpperCase())
                    : null;
                const nameMatch = !idMatch &&
                    fields &&
                    fields.findIndex((r) => r.recordTypeId === coreField.recordTypeId && r.label?.toLowerCase() === coreField.label?.toLowerCase()) >= 0;
                const useCoreField = {
                    id: coreField.refName,
                    tenant: '#system#',
                    scope: 'system',
                    recordTypeId: coreRecType.id,
                    fieldType: coreField.fieldType,
                    codeSet: coreField.codeSet,
                    systemRequired: coreField.systemRequired,
                    codeList: coreField.codeList,
                    codeEnum: coreField.codeEnum,
                    refName: coreField.refName,
                    label: !nameMatch ? idMatch?.label ?? coreField.label : `${coreField.label} (system)`,
                    isList: coreField.isList,
                    formatting: coreField.formatting,
                    referenceType: coreField.referenceType,
                    overrideJoinProperty: coreField.overrideJoinProperty,
                    virtualDbField: coreField.virtualDbField,
                    hidden: idMatch?.hidden ?? coreField.hidden,
                    bulkEditDefault: idMatch?.bulkEditDefault ?? coreField.bulkEditDefault,
                    contactType: coreField.contactType,
                    userTags: idMatch?.userTags ?? coreField.userTags,
                    jsonStorage: idMatch?.jsonStorage ??
                        coreField.jsonStorage ??
                        (coreField.referenceType ? false : coreRecType.usesNewDataModel ? false : true),
                    ignoreBackingTable: coreField.ignoreBackingTable ?? false,
                    disableField: idMatch?.disableField ?? coreField.disableField ?? false,
                    disableFieldFromFieldPanel: coreField.disableField ?? coreField.disableFieldFromFieldPanel ?? false,
                    disableFieldFromSort: coreField.disableField ?? coreField.disableFieldFromSort ?? false,
                    disableFieldFromFilter: coreField.disableField ?? coreField.disableFieldFromFilter ?? false,
                    hasColumn: coreField.hasColumn,
                    formatter: coreField.formatter,
                    pathOverride: coreField.pathOverride,
                    modifiedUser: idMatch?.modifiedUser,
                    modifiedUTC: idMatch?.modifiedUTC,
                    formula2: idMatch?.formula2,
                    masking: coreField.masking,
                };
                if (idMatch) {
                    fields[fields.findIndex((i) => i.recordTypeId === coreField.recordTypeId && i.id?.toUpperCase() === coreField.refName?.toUpperCase())] = useCoreField;
                }
                else {
                    fields.unshift(useCoreField);
                }
            }
        }
    }
    recordType.fields = fields ?? [];
}
