var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'PayrollClassCode';
let PayrollClassCode = class PayrollClassCode {
    classCode;
    state;
    rate;
    payroll;
    employmentType;
    location;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'ClassCode' })
], PayrollClassCode.prototype, "classCode", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'State',
        fieldType: 'codelist',
        codeSet: 'states',
    })
], PayrollClassCode.prototype, "state", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Rate', fieldType: 'decimal' })
], PayrollClassCode.prototype, "rate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payroll', fieldType: 'currency' })
], PayrollClassCode.prototype, "payroll", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'employment-types' })
], PayrollClassCode.prototype, "employmentType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Location' })
], PayrollClassCode.prototype, "location", void 0);
PayrollClassCode = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Payroll Class Code',
        displayNamePlural: 'Payroll Class Codes',
        trailMapTypes: [TrailMapType.QuickAdd],
    })
], PayrollClassCode);
export { PayrollClassCode };
