var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../../data-filters/models';
import { RoleCategories } from '../../../roleEnums';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'AutoNumberCounter';
let AutoNumberCounter = class AutoNumberCounter {
    id;
    name;
    recordType;
    fields;
    autoNumberFormula;
    autoNumberFormulaId;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], AutoNumberCounter.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], AutoNumberCounter.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'recordTypeLookup' })
], AutoNumberCounter.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', isList: true, hasColumn: true })
], AutoNumberCounter.prototype, "fields", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'AutoNumberFormula' })
], AutoNumberCounter.prototype, "autoNumberFormula", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'AutoNumberFormula' })
], AutoNumberCounter.prototype, "autoNumberFormulaId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], AutoNumberCounter.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], AutoNumberCounter.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], AutoNumberCounter.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], AutoNumberCounter.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], AutoNumberCounter.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], AutoNumberCounter.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], AutoNumberCounter.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], AutoNumberCounter.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], AutoNumberCounter.prototype, "deletedUTC", void 0);
AutoNumberCounter = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Auto Number Counter',
        displayNamePlural: 'Auto Number Counters',
        roleCategory: RoleCategories.AutoNumberFormulas,
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'recordType',
                filterType: FilterType.Equals,
            }
        ],
    })
], AutoNumberCounter);
export { AutoNumberCounter };
