var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
const recordTypeId = 'Location';
let Location = class Location {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    locationNumber;
    name;
    effectiveDate;
    untilDate;
    description;
    physicalAddress;
    policyholderId;
    policyholder;
    employerId;
    employer;
    squareFeet;
    yearBuilt;
    areaOccupiedPercentage;
    insideCityLimits;
};
__decorate([
    FieldData({ recordTypeId, label: 'Location ID', fieldType: 'uuid' })
], Location.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Location.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Location.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Location.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Location.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Location.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Location.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Location.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Location.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Location.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], Location.prototype, "locationNumber", void 0);
__decorate([
    FieldData({ recordTypeId })
], Location.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Location.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Location.prototype, "untilDate", void 0);
__decorate([
    FieldData({ recordTypeId })
], Location.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Physical Address', fieldType: 'Address', jsonStorage: true })
], Location.prototype, "physicalAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', label: 'Policyholder Id', referenceType: 'PolicyHolder' })
], Location.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder' })
], Location.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Employer', label: 'Employer Id' })
], Location.prototype, "employerId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Employer' })
], Location.prototype, "employer", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Square Feet', fieldType: 'integer' })
], Location.prototype, "squareFeet", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Year Built', fieldType: 'integer' })
], Location.prototype, "yearBuilt", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Area Occupied Percentage', fieldType: 'percentage-whole-number' })
], Location.prototype, "areaOccupiedPercentage", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Inside City Limits', fieldType: 'boolean' })
], Location.prototype, "insideCityLimits", void 0);
Location = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        lookupProperties: { resultProperties: ['locationNumber', 'name'] },
        displayNameSingular: 'Location',
        displayNamePlural: 'Locations',
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false
        },
        trailMapTypes: [TrailMapType.Detail, TrailMapType.HomePage, TrailMapType.DataEntry, TrailMapType.QuickAdd],
        supports: {
            files: true,
            notes: true,
            docTemplates: true,
            views: true
        },
        workflow: {
            enabled: true,
            generalEvents: true
        },
        recordHistoryConfig: {
            enable: true
        },
        dataModelCustomization: {
            allowCustomFields: true
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'name', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'locationNumber', filterType: FilterType.Equals },
        ],
        roleCategory: RoleCategories.Locations,
        usesNewDataModel: true,
    })
], Location);
export { Location };
