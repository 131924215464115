import {
  FormatDataModelFieldValue,
  GetRecordTypeNamePlural,
  GetRecordTypeNameSingular,
} from './pipes/get-record-type-names.pipe';

import { CommonModule } from '@angular/common';
import { DataFieldSelectorModalComponent } from './components/data-field-selector-modal/data-field-selector-modal.component';
import { DataFilterComponent } from './components/data-filter/data-filter.component';
import { DataFilterConditionComponent } from './components/data-filter-condition/data-filter-condition.component';
import { DataFilterQuickSearchComponent } from './components/data-filter-quick-search/data-filter-quick-search.component';
import { DataFilterQuickSearchFieldComponent } from './components/data-filter-quick-search-field/data-filter-quick-search-field.component';
import { DatamodelFieldAutocompleteComponent } from './components/datamodel-field-autocomplete/datamodel-field-autocomplete.component';
import { DatamodelFieldFilterComponent } from './components/datamodel-field-filter/datamodel-field-filter.component';
import { DatamodelFieldTreeSelectComponent } from './components/datamodel-field-treeselect/datamodel-field-treeselect.component';
import { EditFieldLabelComponent } from './components/edit-field-label/edit-field-label.component';
import { FieldSelectComponent } from './components/field-select/field-select.component';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { FilterGroupConditionComponent } from './components/filter-group-condition/filter-group-condition.component';
import { FilterGroupModalComponent } from './components/filter-group-modal/filter-group-modal.component';
import { GetFullPathLabelPipe, GetLabelPipe } from './pipes/get-label.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { MaskitoDirective } from '@maskito/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { OrderListModule } from 'primeng/orderlist';
import { PickListModule } from 'primeng/picklist';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormulaComponent } from './components/formula/formula.component';
import { FormulaComponentComponent } from './components/formula-component/formula-component.component';
import { DatamodelRecordHeaderComponent } from './components/datamodel-record-header/datamodel-record-header.component';
import { IsLoadingModule } from '@service-work/is-loading';
import { FilterGroupFormFieldComponent } from './components/filter-group-form-field/filter-group-form-field.component';
import { FiltersModule } from '../filters/filters.module';

@NgModule({
  declarations: [
    FieldSelectComponent,
    EditFieldLabelComponent,
    DatamodelFieldAutocompleteComponent,
    GetLabelPipe,
    GetFullPathLabelPipe,
    GetRecordTypeNameSingular,
    GetRecordTypeNamePlural,
    FormatDataModelFieldValue,
    DataFilterComponent,
    DataFilterConditionComponent,
    DatamodelFieldFilterComponent,
    DataFilterQuickSearchFieldComponent,
    DataFilterQuickSearchComponent,
    FilterGroupComponent,
    FilterGroupConditionComponent,
    DatamodelFieldTreeSelectComponent,
    FilterGroupModalComponent,
    DataFieldSelectorModalComponent,
    FormulaComponent,
    FormulaComponentComponent,
    DatamodelRecordHeaderComponent,
    FilterGroupFormFieldComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    PickListModule,
    OrderListModule,
    TreeModule,
    MatChipsModule,
    MatDatepickerModule,
    LayoutModule,
    ReactiveFormsModule,
    TreeSelectModule,
    MaskitoDirective,
    MatButtonToggleModule,
    IsLoadingModule,
    FiltersModule,
  ],
  exports: [
    FieldSelectComponent,
    DatamodelFieldAutocompleteComponent,
    GetLabelPipe,
    GetFullPathLabelPipe,
    GetRecordTypeNameSingular,
    GetRecordTypeNamePlural,
    FormatDataModelFieldValue,
    DataFilterComponent,
    FilterGroupComponent,
    FilterGroupConditionComponent,
    DatamodelFieldTreeSelectComponent,
    FilterGroupModalComponent,
    DataFieldSelectorModalComponent,
    FormulaComponent,
    FormulaComponentComponent,
    DatamodelRecordHeaderComponent,
    FilterGroupFormFieldComponent,
  ],
  providers: [GetLabelPipe, GetFullPathLabelPipe],
})
export class DataModelModule {}
