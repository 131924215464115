var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PayrollReportLineItem';
let PayrollReportLineItem = class PayrollReportLineItem {
    //value is actually the id
    lineItemType;
    description;
    amount;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PayrollReportLineItem.prototype, "lineItemType", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Description', fieldType: 'string' })
], PayrollReportLineItem.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Amount', fieldType: 'currency' })
], PayrollReportLineItem.prototype, "amount", void 0);
PayrollReportLineItem = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Payroll Report Line Item',
        displayNamePlural: 'Payroll Report Line Items',
    })
], PayrollReportLineItem);
export { PayrollReportLineItem };
