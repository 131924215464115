var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { policyStatusList, policyTypeList, } from '../../policies/policyEnums';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var PolicyBillingType;
(function (PolicyBillingType) {
    PolicyBillingType["PayrollReport"] = "payrollReport";
})(PolicyBillingType || (PolicyBillingType = {}));
const recordTypeId = 'Policy';
let Policy = class Policy {
    // Identifiers and Primary Info
    id;
    policyNumber;
    status;
    preCancelStatus;
    // Policy Details
    policyholderId;
    policyHolder;
    agencyId;
    agency;
    xMod;
    worksheetFactor;
    policyPeriod;
    overdueInvoiceId;
    cancellationReason;
    reasonNotes;
    billingScheduleTypeId;
    costDetails;
    // Policy Dates
    cancellationDate;
    rescindCancellationDate;
    pendingCancellationDate;
    effectiveDate;
    expirationDate;
    calculationDate;
    overduePayrollReportDate;
    // Financials
    annualContribution;
    estimatedPremium;
    writtenPremium;
    auditedPremium;
    fees;
    deposit;
    estimatedCommission;
    totalDue;
    // Policy Features
    billingType;
    masterPolicyId;
    worksheetFactorElements;
    payrollClassCodes;
    masterPolicy;
    isMasterPolicy;
    proratedDays;
    // Administrative Info
    billingScheduleType;
    // User-Related Fields
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    agentId;
    agent;
    sourceId;
    latePayrollReportMonths;
    earnedPremium;
    totalIncurred;
    lossRatio;
    locationBasedPayroll;
    policyLocations;
    policyType;
    tailDate;
    retroactiveDate;
};
__decorate([
    FieldData({ recordTypeId, label: 'Policy ID', fieldType: 'uuid' })
], Policy.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Policy.prototype, "policyNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'codelist', codeList: policyStatusList })
], Policy.prototype, "status", void 0);
__decorate([
    FieldData({
        recordTypeId,
        jsonStorage: true,
        fieldType: 'codelist',
        codeList: policyStatusList,
        hidden: ['All'],
    })
], Policy.prototype, "preCancelStatus", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Policy.prototype, "policyholderId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        label: 'Policyholder',
        jsonStorage: true,
    })
], Policy.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Policy.prototype, "agencyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Agency',
        label: 'Agency',
        jsonStorage: true,
    })
], Policy.prototype, "agency", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'decimal',
        label: 'Experience Modifier',
        jsonStorage: true,
    })
], Policy.prototype, "xMod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true })
], Policy.prototype, "worksheetFactor", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy Period', jsonStorage: true })
], Policy.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Policy.prototype, "overdueInvoiceId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeSet: 'cancellationReasons',
        fieldType: 'codelist',
        jsonStorage: true,
    })
], Policy.prototype, "cancellationReason", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Policy.prototype, "reasonNotes", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        referenceType: 'PolicyBillingScheduleType',
        disableField: true,
    })
], Policy.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyCostDetail',
        jsonStorage: true,
        disableField: true,
        isList: true,
    })
], Policy.prototype, "costDetails", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "cancellationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "rescindCancellationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "pendingCancellationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "calculationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], Policy.prototype, "overduePayrollReportDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "annualContribution", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "estimatedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "writtenPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "auditedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "fees", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "deposit", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "estimatedCommission", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Policy.prototype, "totalDue", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Billing Type', jsonStorage: true })
], Policy.prototype, "billingType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Master Policy Id',
        referenceType: 'Policy',
        jsonStorage: false,
    })
], Policy.prototype, "masterPolicyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PayrollReportFactor',
        label: 'Worksheet Factors',
        isList: true,
        jsonStorage: true,
    })
], Policy.prototype, "worksheetFactorElements", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        jsonStorage: true,
        fieldType: 'PayrollClassCode',
    })
], Policy.prototype, "payrollClassCodes", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Policy',
        label: 'Master Policy',
        jsonStorage: false,
    })
], Policy.prototype, "masterPolicy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Policy.prototype, "isMasterPolicy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: true })
], Policy.prototype, "proratedDays", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyBillingScheduleType',
    })
], Policy.prototype, "billingScheduleType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        overrideJoinProperty: 'createUser',
        jsonStorage: true,
    })
], Policy.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Policy.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Policy.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Policy.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Policy.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Policy.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Policy.prototype, "agentId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Agent',
        label: 'Agent',
        jsonStorage: true,
    })
], Policy.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], Policy.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', isList: true, jsonStorage: true })
], Policy.prototype, "latePayrollReportMonths", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Earned Premium', jsonStorage: true })
], Policy.prototype, "earnedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Total Incurred', jsonStorage: true })
], Policy.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Loss Ratio', jsonStorage: true })
], Policy.prototype, "lossRatio", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Location Based Payroll',
        fieldType: 'boolean',
        jsonStorage: true,
    })
], Policy.prototype, "locationBasedPayroll", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Policy Locations',
        isList: true,
        fieldType: 'Location',
        jsonStorage: true,
    })
], Policy.prototype, "policyLocations", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'codelist', codeList: policyTypeList })
], Policy.prototype, "policyType", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'date' })
], Policy.prototype, "tailDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'date' })
], Policy.prototype, "retroactiveDate", void 0);
Policy = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policy',
        displayNamePlural: 'Policies',
        supports: {
            views: true,
            files: true,
            notes: true,
            tasks: true,
            docTemplates: true,
            globalSearch: true,
        },
        globalSearchPaths: ['policyNumber', 'policyHolder.displayName', 'policyHolder.tenantNumber'],
        usesNewDataModel: true,
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        lookupProperties: {
            resultProperties: ['policyNumber', 'effectiveDate', 'expirationDate'],
            filterProperties: ['policyNumber', 'policyHolder.displayName'],
        },
        roleCategory: RoleCategories.Policies,
        recordHistoryConfig: {
            enable: true,
        },
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: [
                'POLICY-CANCELLED',
                'POLICY-REINSTATED',
                'POLICY-PREPENDINGCANCELLATION',
                'POLICY-PENDINGCANCELLATION',
                'POLICY-RESCIND-CANCELLATION',
                'POLICY-UNBOUND',
            ],
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'policyHolder',
                width: 'xx-large',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'policyNumber',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'status',
                filterType: FilterType.Equals,
            },
        ],
    })
], Policy);
export { Policy };
