var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'InvoiceLine';
let InvoiceLine = class InvoiceLine {
    //pk
    id;
    //fk
    invoiceId;
    invoice;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    //jdata
    sequence;
    ref;
    description;
    amount;
    commissionable;
    formula;
    lineItemTypeId;
    lineItemType;
    taxRate;
    taxAmount;
    policyCostDetail;
    quantity;
    debit;
    accountNumber;
    isAutoreverse;
    subAccount;
    sourceId;
    itemRef;
    partOfPremium;
    paidAllocationAmount;
    adjustmentReason;
    preAdjustmentAmount;
    adjusted;
    adjustmentDateUTC;
};
__decorate([
    FieldData({ recordTypeId })
], InvoiceLine.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, referenceType: 'Invoice' })
], InvoiceLine.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], InvoiceLine.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceLine.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceLine.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceLine.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], InvoiceLine.prototype, "sequence", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], InvoiceLine.prototype, "ref", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], InvoiceLine.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], InvoiceLine.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], InvoiceLine.prototype, "commissionable", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], InvoiceLine.prototype, "formula", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceLine.prototype, "lineItemTypeId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'LineItemType' })
], InvoiceLine.prototype, "lineItemType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'percentage-whole-number',
        jsonStorage: true,
    })
], InvoiceLine.prototype, "taxRate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], InvoiceLine.prototype, "taxAmount", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'jsonb',
        disableField: true,
        jsonStorage: true,
    })
], InvoiceLine.prototype, "policyCostDetail", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "quantity", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "debit", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "accountNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "isAutoreverse", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "subAccount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "sourceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "itemRef", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "partOfPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true, disableField: true })
], InvoiceLine.prototype, "paidAllocationAmount", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], InvoiceLine.prototype, "adjustmentReason", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], InvoiceLine.prototype, "preAdjustmentAmount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], InvoiceLine.prototype, "adjusted", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', jsonStorage: true, label: 'Adjusted On' })
], InvoiceLine.prototype, "adjustmentDateUTC", void 0);
InvoiceLine = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Invoice Line',
        displayNamePlural: 'Invoice Lines',
        supports: {
            views: true,
        },
        recordHistoryConfig: {
            enable: true,
            relatedProperty: 'invoiceId',
        },
        usesNewDataModel: true,
    })
], InvoiceLine);
export { InvoiceLine };
