var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'PolicyCostDetail';
let PolicyCostDetail = class PolicyCostDetail {
    description;
    partOfPremium;
    lineItemTypeId;
    lineItemType;
    coverage;
    total;
    prorationType;
    taxable;
    tax;
    paidFullOnDeposit;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], PolicyCostDetail.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyCostDetail.prototype, "partOfPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyCostDetail.prototype, "lineItemTypeId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'LineItemType' })
], PolicyCostDetail.prototype, "lineItemType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], PolicyCostDetail.prototype, "coverage", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyCostDetail.prototype, "total", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], PolicyCostDetail.prototype, "prorationType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyCostDetail.prototype, "taxable", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyCostDetail.prototype, "tax", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyCostDetail.prototype, "paidFullOnDeposit", void 0);
PolicyCostDetail = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Policy Cost Detail',
        displayNamePlural: 'Policy Cost Details',
        dataModelCustomization: {
            allowCustomFields: true,
        },
    })
], PolicyCostDetail);
export { PolicyCostDetail };
